import axios from 'axios';
export const APP_API_PATH = window.__RUNTIME_CONFIG__.REACT_APP_API_PATH;
export const APP_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_APP_CONTEXT_PATH;
export const CHAT_WS_URL = window.__RUNTIME_CONFIG__.REACT_CHAT_WS_URL;
export const CHAT_API_PATH = window.__RUNTIME_CONFIG__.REACT_CHAT_API_PATH;
export const MP_API_PATH = window.__RUNTIME_CONFIG__.REACT_MP_API_PATH;
export const MP_CONTEXT_PATH = window.__RUNTIME_CONFIG__.REACT_MP_CONTEXT_PATH;
export const CHAT_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_CHAT_CONTEXT_PATH;
export const PDS_API_PATH = window.__RUNTIME_CONFIG__.REACT_PDS_API_PATH;
export const PDS_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_PDS_CONTEXT_PATH;

axios.defaults.baseURL = MP_CONTEXT_PATH;
axios.defaults.withCredentials = true;
axios.defaults.responseType = 'json';

/**
 * URL 메뉴 그룹 추출 정규식
 * @type {RegExp}
 */
export const MENU_GROUP_RE = /\/main\/([^\/]+)(?=\/)?/;

export const EMART_TYPES = [{ label: '이마트', value: 'EM' }];

export const EMART_AREAS = [
  { label: '서울', value: 'A' },
  { label: '인천', value: 'C' },
  { label: '경기', value: 'I' },
  { label: '대전', value: 'F' },
  { label: '세종', value: 'Q' },
  { label: '충청', value: 'N' },
  { label: '대구', value: 'D' },
  { label: '경상', value: 'J' },
  { label: '울산', value: 'G' },
  { label: '부산', value: 'B' },
  { label: '전라', value: 'L' },
  { label: '광주', value: 'E' },
  { label: '강원', value: 'H' },
  { label: '제주', value: 'P' },
];

export const SYSTEM_MESSAGE_TICKET_TRANSFERRED_BY_AGENT =
  'SYSTEM_MESSAGE_TICKET_TRANSFERRED_BY_AGENT';
export const SYSTEM_MESSAGE_TICKET_ASSIGNED_BY_SYSTEM =
  'SYSTEM_MESSAGE_TICKET_ASSIGNED_BY_SYSTEM';
export const SYSTEM_MESSAGE_TICKET_ASSIGNED_BY_AGENT =
  'SYSTEM_MESSAGE_TICKET_ASSIGNED_BY_AGENT';

export const COOKIE_KEY_CHANNEL_ID = 'CHANNEL_ID';
export const COOKIE_KEY_USER_ID = 'USER_ID';
export const COOKIE_KEY_USER_KEY = 'USER_KEY';
export const COOKIE_KEY_NICKNAME = 'NICKNAME';
export const COOKIE_KEY_TICKET_ID = 'TICKET_ID';
export const COOKIE_KEY_EMART_BRANCH_ID = 'EMART_BRANCH_ID';
export const COOKIE_KEY_EMART_BRANCH_NAME = 'EMART_BRANCH_NAME';
export const COOKIE_KEY_BRAND_ID = 'BRAND_ID';
export const COOKIE_KEY_BRAND_NM = 'BRAND_NM';
export const COOKIE_KEY_ROD_SEARCH_GUIDE = 'ROD_SEARCH_GUIDE';
export const COOKIE_KEY_SCENARIO_ID = 'SCENARIO_ID';
export const COOKIE_KEY_SCENARIO_YN = 'SCENARIO_YN';
export const COOKIE_KEY_TEL_NO = 'TEL_NO';

export const TICKET_FIELD_KEYS = {
  /**
   * 유입 채넝 ID
   */
  inflowId: 'inflow-id',

  /**
   * 유입 채넝 유형
   */
  inflowType: 'inflow-type',

  /**
   * 유입 챗봇 유형
   */
  inflowCode: 'inflow-code',

  /**
   * 유입 일시
   */
  inflowAt: 'inflow-at',

  /**
   *  유입 지점 ID
   */
  branchId: 'branch-id',

  /**
   * 유입 지점명
   */
  branchName: 'branch-name',

  /**
   *  유입 지점 Code
   */
  branchTelno: 'branch-telno',

  /**
   *  유입 브랜드ID
   */
  brandId: 'brand-id',

  /**
   * 유입 브랜드명
   */
  brandName: 'brand-name',
};

/**
 * 검색 제외 지점
 * @type {Set<string>}
 */
export const SEARCH_EXCLUDES = new Set(['0000']); //

export const EMART_THEMES = {
  EM: {
    '--emart-point-color': '#ffe926',
    '--emart-point-color-darken': '#ead200',
    '--emart-point-color-lighten': '#ffed51',
    '--erody-hood-color': '#fff',
    '--emart-point-hex-color': '255, 233, 3',
  },
  TR: {
    '--emart-point-color': '#A6DD27',
    '--emart-point-color-darken': '#A6DD27',
    '--emart-point-color-lighten': '#A6DD27',
    '--erody-hood-color': '#fff',
    '--erody-tr-badge': 'display',
    '--emart-point-hex-color': '166, 221, 39',
  },
};
