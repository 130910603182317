import ReactGA from 'react-ga4';
import {format} from 'date-fns';
import Cookies from 'js-cookie';
import {COOKIE_KEY_EMART_BRANCH_ID, COOKIE_KEY_EMART_BRANCH_NAME, COOKIE_KEY_TICKET_ID} from '../const/AppConst';

export const eventTrack = (sessionId, userId, category, action, body = {}, eventType = 'click') => {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

    const now = new Date();
    const date = format(now, 'yyyyMMdd');
    const time = format(now, 'HHmmSS');
    const param = Object.assign(
        {},
        {
            category,
            action,
        },
        {
            'key1_timestamp_yyyymmdd': date,
            'key2_timestamp_hh24miss': time,
            'key3_sessionid'         : sessionId,    //세션아이디
            'key4_userid'            : userId,	  //사용자아이디
            'key5_event_type'        : eventType,  // 이벤트 (Default : click)
            'key6_branch_id'         : Cookies.get(COOKIE_KEY_EMART_BRANCH_ID),
            'key7_branch_name'       : Cookies.get(COOKIE_KEY_EMART_BRANCH_NAME),
            'key8_ticket_id  '       : Cookies.get(COOKIE_KEY_TICKET_ID),
            'url'                    : window.location.href
        },
        body
    );

    if (trackingId) {
        ReactGA.event(action, param);
    } else {
        console.log(param);
    }
};

export const makeEventTrackFn = (sessionId, userId) =>
    (category, action, body, eventType) => {
        eventTrack(sessionId, userId, category, action, body, eventType);
    };