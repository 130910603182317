import React from 'react';
import PropTypes from 'prop-types';
import Styles from './BouncingDots.module.scss';

const BouncingDots = () =>
    <div className={Styles.spinner}>
        <div className={Styles.bounce1}/>
        <div className={Styles.bounce2}/>
        <div className={Styles.bounce3}/>
    </div>;

BouncingDots.propTypes = {
    theme: PropTypes.string
};

export default BouncingDots;
