import { CHAT_CONTEXT_PATH, CHAT_WS_URL } from '../const/AppConst';
import {
  ChannelStore,
  ChatStore,
  RSocket,
  Sort,
  TicketStore,
  QuickMsgStore,
} from '@qbot-chat/qbot-uikit';
import { navigate } from '../helper/HistoryHelper';
import { NotificationManager } from 'react-notifications';

const rSocket = new RSocket(
  CHAT_WS_URL,
  CHAT_CONTEXT_PATH,
  (error) => {
    let message = error?.response?.data?.message;
    NotificationManager.error(
      message || `${error.response?.status} ${error.response?.statusText}`,
    );
  },
  (message) => {
    NotificationManager.error(message);
  },
);

// rSocket.setNotificationHandler((type,message)=>{
// })

window.rSocket = rSocket;
const chatStore = new ChatStore();
chatStore.setInnerLinkFn(navigate);

const ticketStore = new TicketStore();
const quickMsgStore = new QuickMsgStore();

let channelStore = new ChannelStore({
  rowsPerPage: 10,
  paginationSize: 3,
  sort: new Sort('createdAt', Sort.SORT_DIRECTION.DESC),
});

export { rSocket, chatStore, channelStore, ticketStore, quickMsgStore };
