import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './Loading.module.scss';
import { ReactComponent as Character } from './character.svg';
import { ReactComponent as Check } from './ic_24_check.svg';
import BouncingDots from './BouncingDots';
import { Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  COOKIE_KEY_BRAND_ID,
  COOKIE_KEY_BRAND_NM,
  COOKIE_KEY_CHANNEL_ID,
  COOKIE_KEY_EMART_BRANCH_ID,
  COOKIE_KEY_EMART_BRANCH_NAME,
  COOKIE_KEY_TICKET_ID,
  COOKIE_KEY_USER_ID,
  EMART_THEMES,
  TICKET_FIELD_KEYS,
  COOKIE_KEY_SCENARIO_YN,
  COOKIE_KEY_TEL_NO,
} from '../../const/AppConst';
import Cookies from 'js-cookie';
import { eventTrack } from '../../util/Utils';
import { format } from 'date-fns';
import { rSocket, ticketStore } from '../../store/FrontChatStore';
import { TICKET_STATUS } from '@qbot-chat/qbot-uikit';

const ListItem = ({ text }) => (
  <div className={Styles.ListItem}>
    <div className={Styles.Oval}>
      <Check />
    </div>
    {text}
  </div>
);

ListItem.propTypes = {
  text: PropTypes.string.isRequired,
};

const Loading = ({ location, match }) => {
  const user_id = useMemo(() => Cookies.get(COOKIE_KEY_USER_ID), []);
  // const user_key = useMemo(() => Cookies.get(COOKIE_KEY_USER_KEY), []);
  const channelId = useMemo(() => Cookies.get(COOKIE_KEY_CHANNEL_ID), []);
  // const nickname = useMemo(() => Cookies.get(COOKIE_KEY_NICKNAME), []);
  // const scenario = useMemo(() => Cookies.get(COOKIE_KEY_SCENARIO_ID), []);
  const telNo = useMemo(() => Cookies.get(COOKIE_KEY_TEL_NO), []);

  const [channelUrl, setChannelUrl] = useState(null);
  const [error, setError] = useState(false);
  const [expired, setExpired] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [theme, setTheme] = useState(EMART_THEMES.EM);
  const [landing, setLanding] = useState(false);

  // const axiosWithTimeout = axios.create({ timeout: 10000 });

  const init = async () => {
    console.log('channelId : ' + channelId);

    if (!channelId) return;

    let channelCode;
    let chnlDts;
    let cbotCd;
    const isReloaded = location.state?.reload;

    let landingRequired = false;
    const isFromLanding = match.params.fromLanding === 'fromLanding';
    if (isReloaded) console.log('새로 진입. 유입로그 GA 기록..');

    let response;
    try {
      //response = await rSocket.requestChatApi(``, {});
      axios.defaults.baseURL = '/mp/api/v1';
      let params = {
        userId: user_id,
        chatBotType: telNo,
      };
      //response = await axios.post(`/channel-log/check-ars-expired/${user_id}?rn=${uuidv4()}`);
      response = await axios.post(`/channel-log/check-ars-expired`, params);
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        console.log('timeout');
        setLoaded(true);
      } else {
        setExpired(true);
      }
      return;
    }

    try {
      let arsChannelLog;
      if (response?.data) {
        const logs = response.data;
        const logMap = new Map(logs.map((log) => [log.type, log]));

        console.log(logMap);

        arsChannelLog = logMap.get('ARS');

        console.log(arsChannelLog);

        if (arsChannelLog && arsChannelLog.id) {
          const infloDts = new Date(arsChannelLog?.createdAt);
          //   const date = format(infloDts, 'yyyyMMdd');
          //   const time = format(infloDts, 'HHmmSS');

          Cookies.set(COOKIE_KEY_CHANNEL_ID, channelId, { expires: 1 });
          // Cookies.set(COOKIE_KEY_USER_KEY, user_key, {expires: 1});

          if (arsChannelLog?.branch?.id)
            Cookies.set(COOKIE_KEY_EMART_BRANCH_ID, arsChannelLog?.branch?.id, {
              expires: 1,
            });

          if (arsChannelLog?.branch?.name)
            Cookies.set(
              COOKIE_KEY_EMART_BRANCH_NAME,
              arsChannelLog?.branch?.name,
              { expires: 1 },
            );

          if (arsChannelLog?.brand?.id) {
            Cookies.set(COOKIE_KEY_BRAND_ID, arsChannelLog?.brandId, {
              expires: 1,
            });
            setTheme(EMART_THEMES[arsChannelLog?.brand?.id]);
          }

          if (arsChannelLog?.brand?.name) {
            Cookies.set(COOKIE_KEY_BRAND_NM, arsChannelLog?.brand?.name, {
              expires: 1,
            });
            document.title = `${arsChannelLog?.brand?.name} 디지털 상담`;
          }

          landingRequired = arsChannelLog?.landingRequired;

          if (logMap.get('SDK')) {
            cbotCd = 'SDK';
          } else if (logMap.get('IOS')) {
            cbotCd = 'IOS';
          } else {
            cbotCd = 'AND';
          }

          chnlDts = infloDts;
          channelCode = arsChannelLog.type;

          if (isReloaded) {
            logs.forEach((log) => {
              const logCreatedAt = new Date(log?.createdAt);
              const date = format(logCreatedAt, 'yyyyMMdd');
              const time = format(logCreatedAt, 'HHmmSS');

              eventTrack(
                channelId,
                user_id,
                'event',
                `인입경로-${log.type}`,
                {
                  key1_timestamp_yyyymmdd: date,
                  key2_timestamp_hh24miss: time,
                },
                log.type,
              );
            });
          }
        }
      }

      // 고객의 아직 닫히지 않은 티켓이 있는지 확인하다.
      const channel = ticketStore.activeTickets?.find(
        (channel) => channel?.properties[`inflow-id`] === channelId,
      );
      if (channel) {
        console.log('기존 닫기전 티켓 확인됨.. ');
        rSocket.chatStore.setChannel({
          ...channel,
          status: TICKET_STATUS.ACTIVE,
        });
        setChannelUrl(channel.id);
      } else {
        // Use a user ID that is unique to your Sendbird application.
        let ticketCustomField = {};
        ticketCustomField[TICKET_FIELD_KEYS.inflowId] = channelId;
        ticketCustomField[TICKET_FIELD_KEYS.inflowType] = channelCode;
        ticketCustomField[TICKET_FIELD_KEYS.inflowAt] = chnlDts;
        ticketCustomField[TICKET_FIELD_KEYS.inflowCode] = cbotCd;
        if (arsChannelLog) {
          ticketCustomField[TICKET_FIELD_KEYS.branchName] =
            arsChannelLog?.branch?.name;
          ticketCustomField[TICKET_FIELD_KEYS.branchId] =
            arsChannelLog?.branch?.id;
          ticketCustomField[TICKET_FIELD_KEYS.branchTelno] =
            arsChannelLog?.branch?.telno;
          ticketCustomField[TICKET_FIELD_KEYS.brandId] =
            arsChannelLog?.brand?.id;
          ticketCustomField[TICKET_FIELD_KEYS.brandName] =
            arsChannelLog?.brand?.name;
        }
        // let {data:content} = await rSocket.requestChatApi(`/channel-log/greeting/message/content`, ticketCustomField);
        axios.defaults.baseURL = '/mp/api/v1';
        //let {data:content} = await axios.post(`/channel-log/greeting/message/content`, ticketCustomField);
        //console.log(content);
        if (landingRequired && !isFromLanding) {
          setLanding(true);
        } else {
          console.log('ticketCustomField : ', ticketCustomField);
          //COOKIE_KEY_SCENARIO_YN
          let channelParam = {
            properties: ticketCustomField,
            // greetingMessage: {
            //     type: MESSAGE_TYPE.GREETING,
            //     content: null
            // },
            team: {
              id: arsChannelLog?.branch?.team?.id,
            },
            companyId: arsChannelLog?.brand?.companyId,
            inflowType: 'CHT',
          };
          if (Cookies.get(COOKIE_KEY_SCENARIO_YN) == 'Y') {
            channelParam['scenario'] = { sid: 'SID0002' };
          }
          console.log('channelParam : ', channelParam);
          let newChannel = await ticketStore.createChannel(channelParam);

          console.log('new Channel : ', newChannel);

          Cookies.set(COOKIE_KEY_TICKET_ID, newChannel.id);
          setChannelUrl(newChannel.id);
        }
      }
    } catch (err) {
      console.log(err);
      if (err.code === 'ECONNABORTED') {
        console.log('timeout');
        setLoaded(true);
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    console.log('loading useEffect');
    init().then();
  }, [channelId]);

  if (landing) return <Redirect to="/landing" push={false} />;

  if (error) return <Redirect to="/maintenance" push={false} />;

  if (expired) return <Redirect to="/expired" push={false} />;

  if (loaded) return <Redirect to="/loaded" />;

  if (channelUrl)
    return (
      <Redirect
        to={{
          pathname: `/chat/${channelUrl}`,
          state: { from: 'loading', status: 'not-loaded' },
        }}
        push={false}
      />
    );

  return (
    <div className={Styles.Loading} style={EMART_THEMES[theme]}>
      <div
        className="d-flex justify-center"
        style={{ margin: '40px 0 16px 0' }}
      >
        <Character />
      </div>
      <div className="d-flex flex-column justify-center align-items-center">
        <div>디지털 상담으로 연결 중입니다.</div>
        <div>잠시만 기다려 주세요.</div>
      </div>
      <div
        className="d-flex justify-center"
        style={{ margin: '11px 0 27px 0' }}
      >
        <BouncingDots />
      </div>

      <div className={Styles.List}>
        <ListItem text="대기 하지 않고" />
        <ListItem text="눈으로 보면서" />
        <ListItem text="간편하게 처리" />
      </div>

      <div className={Styles.Footer}>
        <div>이용 중 데이터 요금이 부과 될수 있습니다.</div>
        <div>연결 후 화면에서 선택해주세요.</div>
      </div>
    </div>
  );
};

Loading.propTypes = {};

export default withRouter(Loading);
