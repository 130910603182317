import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { Close } from '@qbot-chat/qbot-uikit/chat';
import { chatStore, rSocket } from '../store/FrontChatStore';
import { navigate } from '../helper/HistoryHelper';
import Cookies from 'js-cookie';
import {
  COOKIE_KEY_BRAND_NM,
  COOKIE_KEY_CHANNEL_ID,
  COOKIE_KEY_USER_KEY,
} from '../const/AppConst';
import Styles from './CloseView.module.scss';
import qs from 'qs';
import { makeObservable, observable } from 'mobx';

@observer
class CloseView extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      brandName: Cookies.get(COOKIE_KEY_BRAND_NM) || '퀀텀AI',
    };
    let queryParam = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let isRating = queryParam.isRating;
    rSocket.requestChatApi(`/app/setting`).then(({ data }) => {
      this.message =
        isRating === 'true'
          ? data?.chatRatingClosedMessage
          : data?.chatClosedMessage;
    });
  }

  @observable
  message;

  componentDidMount() {}

  render() {
    return (
      <div className={`d-flex flex-column flex-default overflow-hidden`}>
        <div className={Styles.Header}>
          <div className={Styles.NotUseButton}></div>

          <p className={Styles.Label}>{this.state.brandName} 상담봇</p>

          <div className={Styles.NotUseButton}></div>
        </div>
        <div style={{ flex: 1 }}>
          <Close
            message={this.message}
            onRestart={() => {
              //   let customer = rSocket.sender;
              chatStore.setChannel(undefined);
              navigate(
                `/?user_id=${Cookies.get(COOKIE_KEY_USER_KEY)}&chnlId=${Cookies.get(COOKIE_KEY_CHANNEL_ID)}`,
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default CloseView;
