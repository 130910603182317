import React from 'react';
import {ReactComponent as UnderConstruction} from './ic_140_under-construction.svg';
import Styles from './Maintenance.module.scss'


const Maintenance = () => (
    <div className={Styles.Maintenance}>
        <div className="d-flex justify-center" style={{margin: '154px 0 32px 0'}}>
            <UnderConstruction/>
        </div>
        <div className={`d-flex flex-column justify-center align-items-center ${Styles.Title}`}>
            <div>서비스 점검 중입니다</div>
            <div>잠시 후 다시 이용해 주세요</div>
        </div>
    </div>
);

export default Maintenance;
